var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mr-0" },
    [
      this.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      this.teams != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              search: _vm.nameSearchable,
              items: _vm.teams,
              headers: _vm.headersTeam,
              dense: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.created_at != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.created_at.split("T")[0]) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[3]
                                ) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[4]
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          on: {
                            click: function ($event) {
                              return _vm.redirectReadTeam(item.name)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            width: "auto",
                            height: "auto",
                            color: "py-3",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4",
                                        },
                                        [_vm._v(" Team ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          clearable: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.nameSearchable,
                                          callback: function ($$v) {
                                            _vm.nameSearchable = $$v
                                          },
                                          expression: "nameSearchable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.userRole == "operation" ||
                              _vm.userRole == "sales_manager"
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.redirectNewTeam },
                                    },
                                    [_vm._v(" New ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1415704373
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }